import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
Vue.config.productionTip = false;


import './assets/css/bootstrap.min.css'
import './assets/js/bootstrap.min'
import '@/assets/css/font.less'

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app");