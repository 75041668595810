<template>
  <div class="index-background">
    <div class="stars"></div>
    <img id="rocket" src="../assets/images/star.png" />
  </div>
</template>

<script>
export default {
  name: "index",
  components: {},
  data() {
    return {};
  },
  created() {
    window.onload = () => {
      let dom = document.querySelector(".stars");
      let w =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      let h =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      for (let i = 0; i < 300; i++) {
        let starDom = document.createElement("div");
        if (i == 0) {
          starDom.setAttribute("class", "star");
          dom.appendChild(starDom);
        } else {
          let left = this.getRandomNumber(0, w);
          let top = this.getRandomNumber(0, h - 950);
          starDom.setAttribute("class", "star");
          starDom.setAttribute(
            "style",
            `top: ${top}px; left: ${left}px; animation-delay: ${(i % 3) + 1}s;`
          );
          dom.appendChild(starDom);
        }
      }
    };
  },
  methods: {
    getRandomNumber(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
  },
};
</script>
<style>
@-webkit-keyframes star-opacity {
  0% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }

  60% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes star-opacity {
  0% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }

  60% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
@-webkit-keyframes star-pos {
  0% {
    transform: scale(0) rotate(0) translate3d(0, 0, 0);
    -webkit-transform: scale(0) rotate(0) translate3d(0, 0, 0);
    -moz-transform: scale(0) rotate(0) translate3d(0, 0, 0);
  }

  100% {
    transform: scale(1) rotate(0) translate3d(-450px, 450px, 0);
    -webkit-transform: scale(1) rotate(0) translate3d(-450px, 450px, 0);
    -moz-transform: scale(1) rotate(0) translate3d(-450px, 450px, 0);
  }
}

@keyframes star-pos {
  0% {
    transform: scale(0) rotate(0) translate3d(0, 0, 0);
    -webkit-transform: scale(0) rotate(0) translate3d(0, 0, 0);
    -moz-transform: scale(0) rotate(0) translate3d(0, 0, 0);
  }

  100% {
    transform: scale(1) rotate(0) translate3d(-450px, 450px, 0);
    -webkit-transform: scale(1) rotate(0) translate3d(-450px, 450px, 0);
    -moz-transform: scale(1) rotate(0) translate3d(-450px, 450px, 0);
  }
}

body {
  padding: 0;
  margin: 0;
}

.index-background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(-225deg, #473b7b 0%, #3584a7 51%, #30d2be 100%);
  overflow: hidden;
}

.index-background .stars {
  height: 0;
}

.index-background .star {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #fff;
  top: 100px;
  left: 400px;
  position: relative;
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
  -webkit-animation: star-opacity 5s infinite ease-in,
    star-pos 5s infinite ease-in;
  animation: star-opacity 5s infinite ease-in, star-pos 5s infinite ease-in;
  -webkit-box-shadow: 0 0 5px 5px rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 5px 5px rgba(255, 255, 255, 0.3);
  opacity: 0;
  z-index: 2;
}

.index-background .star:after {
  content: "";
  display: block;
  top: 0;
  left: 6px;
  border: 0 solid #fff;
  border-width: 0 90px 2px;
  border-color: transparent transparent transparent rgba(255, 255, 255, 0.3);
  -webkit-transform: rotate(-45deg) translate3d(1px, 7px, 0);
  transform: rotate(-45deg) translate3d(1px, 7px, 0);
  -webkit-box-shadow: 0 0 1px 0 rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 1px 0 rgba(255, 255, 255, 0.1);
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
}

#rocket {
  position: absolute;
  left: 20%;
  margin-left: -125px;
  top: 300px;
  animation: move 0.7s linear 0s infinite alternate;
  width: 260px;
  z-index: 999;
}

@keyframes move {
  from {
    transform: translate(-30px, -30px);
  }

  to {
    transform: translate(30px, 30px);
  }
}
</style>