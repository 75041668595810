<template>
  <div id="app">
    <star></star>
    <div class="counent">
      <div class="header">
        <div class="logo">
          Star Coin
        </div>
      </div>
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
  </div>
</template>
<script>
import star from "./components/star";
export default {
  components: { star },
  name: "App",
  data() {
    return {};
  },
  created() {},
  methods: {
  },
  computed: {

  },
  //监听路由变化
  watch: {
    $route(to, from) {},
  },
};
</script>
<style lang="scss">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: Rainbow;
  font-size: 20px;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

*::-webkit-scrollbar {
  width: 8px !important;
  height: 8px;
}

*::-webkit-scrollbar-button:start:decrement,
*::-webkit-scrollbar-button:end:increment {
  height: 0;
  background-color: transparent;
}

*::-webkit-scrollbar-corner {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  /* -webkit-border-radius: 6px; */
  /* border-radius: 6px; */
  background-color: rgba(0, 0, 0, 0.2);
}
#app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
}
.counent {
  flex: 1;
  z-index: 99;
  width: 90%;
  margin: auto;
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
     .logo{
      color: #ffffff;
     }
  }
}
</style>